import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

@Injectable({
	providedIn: 'root'
})
export class AuthenticatedGuard extends KeycloakAuthGuard  {
	constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
		super(router, keycloakAngular);
	}

	isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (!this.authenticated) {
				this.keycloakAngular.login({
					redirectUri: window.location.origin + state.url
				})
					.catch(e => console.error(e));
				return reject('not authenticated');
			}

			const requiredRoles: string[] = route.data.roles;
			if (!requiredRoles || requiredRoles.length === 0) {
				return resolve(true);
			} else {
				if (!this.roles || this.roles.length === 0) {
					resolve(false);
				}
				resolve(requiredRoles.some(role => this.roles.indexOf(role) > -1));
			}
		});
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return true;
	}
}
