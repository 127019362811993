import {RackgraphComponent, RackJson, RackRow} from '@211-feelink/rackgraph';
import {DOCUMENT, formatDate} from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	HostListener,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {MatDrawer} from '@angular/material/sidenav';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslocoService} from '@ngneat/transloco';
import {plainToClass, plainToInstance} from 'class-transformer';
import {KeycloakService} from 'keycloak-angular';
import {clone, cloneDeep, isEqual} from 'lodash';
import {BehaviorSubject, combineLatest, concat, firstValueFrom, Observable, Subscription} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {Site, SiteFilter} from 'src/app/objects/customer/site/site';
import {Asset, AssetFilter, AssetInput, MultipleAssetInput} from '../../../objects/asset/asset';
import {AssetType} from '../../../objects/asset/asset-type.constants';
import {AssetService} from '../../../objects/asset/asset.service';
import {CopyService} from '../../../objects/asset/copy.service';
import {SiteService} from '../../../objects/customer/site/site.service';
import {LockService} from '../../../objects/lock/lock.service';
import {Rack, RackFilter, RackInput} from '../../../objects/rack/rack';
import {RackService} from '../../../objects/rack/rack.service';
import {Synoptic, SynopticFilter} from '../../../objects/synoptic/synoptic';
import {SynopticService} from '../../../objects/synoptic/synoptic.service';
import {User} from '../../../objects/user/user';
import {BackButtonComponent} from '../../../shared/component/back-button/back-button.component';
import {
	ConfirmDialogComponent,
	ConfirmDialogData
} from '../../../shared/component/confirm-dialog/confirm-dialog.component';
import {UserInfo} from '../../../shared/component/user-info/user-info';
import {AbstractIdentifier} from '../../../shared/dto/generic/abstract-identifier';
import {ParameterTypeEnum} from '../../../shared/enum/generic/parameter-type.enum';
import {GenericTriStateEnum} from '../../../shared/enum/generic/state.enum';
import {CanExitInterface} from '../../../shared/guard/exit.guard.service';
import {FileManagementService} from '../../../shared/service/file-management/file-management.service';
import {ParameterService} from '../../../shared/service/generic/parameter.service';
import {SharedService} from '../../../shared/service/shared/shared.service';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';
import {TitleService} from '../../../shared/service/title/title.service';
import {UserService} from '../../../shared/service/user/user.service';
import {Utils} from '../../../shared/utils/utils';
import {RackAddAccessoryDialogComponent} from '../rack-add-accessory-dialog/rack-add-accessory-dialog.component';
import {RackAddDialogComponent} from '../rack-add-dialog/rack-add-dialog.component';

export interface RackMenuItem {
	active?: boolean;
	icon: string;
	tooltip: string;
}

export interface RackAssetsViewArticle {
	uuid?: string;
	name: string;
	imageBlob?: string;
	height: number;
	asset: Asset;
	shelfAssetsImages?: Map<string, string>;
}

export interface RackAssetsView {
	roomUuid?: string;
	roomName?: string;
	open?: boolean;
	articles?: RackAssetsViewArticle[];
}

const RACK_FIELDS: (keyof Rack | string)[] = ['uuid', 'version', 'lastVersion', 'name', 'json', 'project {uuid}', 'site {uuid, name, customer {uuid, name}}', 'backDoor', 'backLock', 'bothSide', 'color', 'depth', 'feet', 'frontDoor', 'frontLock', 'side', 'size', 'structure'];
const ASSET_FIELDS: (keyof Asset | string)[] = ['uuid', 'name', 'rackgraphId', 'synoptic {uuid, version, room {uuid, name}}', 'rack {uuid, version, name}', 'rackAccessoryHeight', 'type', 'comment', 'location', 'article {uuid, version, ref, name, imageUrl, rackable, category {uuid, name}}', 'shelf {uuid, name}', 'shelfAssets {uuid, name, type, comment, article {uuid, version, imageUrl}}'];

@Component({
	selector: 'app-rack',
	templateUrl: './rack.component.html',
	styleUrls: ['./rack.component.scss']
})
export class RackComponent extends UserInfo implements OnInit, AfterViewInit, OnDestroy, CanExitInterface {
	@ViewChild(BackButtonComponent) backBtn: BackButtonComponent;
	@ViewChild('drawer') drawer: MatDrawer;
	@ViewChild('rackFrontDiv', {read: ViewContainerRef}) rackFrontVcr: ViewContainerRef;
	@ViewChild('rackBackDiv', {read: ViewContainerRef}) rackBackVcr: ViewContainerRef;
	@ViewChild(RackgraphComponent) rackgraph: RackgraphComponent;
	rack: Rack;
	form: UntypedFormGroup;
	formPristine: object;
	sideView: number;
	showComments: boolean = true;
	hideRacked: boolean = false;
	rowHeight: number = 20;
	assetsView: RackAssetsView[] = [];
	menuContent: RackMenuItem[] = [
		{
			icon: 'server-outline',
			tooltip: 'rack.menu.caracteristics'
		},
		{
			icon: 'merge',
			tooltip: 'rack.menu.assets'
		},
		{
			icon: 'settings',
			tooltip: 'rack.menu.settings'
		}
	];
	assets: Asset[] = [];
	pristineAssets: Asset[] = [];
	rackJsonPristine: string;
	rackSizeArray: number[] = [];
	subscriptions: Subscription[] = [];
	racks: Rack[] = [];
	sites: Site[] = [];
	synosInSameState: Synoptic[] = [];
	parametersMap: Map<string, string[]> = new Map<ParameterTypeEnum, string[]>();
	pageStyle: HTMLStyleElement;
	rackSizeTimeout: any;
	rackSize: number;

	readonly: boolean = true;
	canUnlock: boolean = false;

	rackLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	pageLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	loaded: boolean = false;

	forceExit: boolean = false;

	constructor(public sharedService: SharedService,
				private _fb: UntypedFormBuilder,
				private _activeRoute: ActivatedRoute,
				private _router: Router,
				private _translator: TranslocoService,
				private _titleService: TitleService,
				private _cdRef: ChangeDetectorRef,
				private _dialog: MatDialog,
				@Inject(DOCUMENT) private document: Document,
				private _snackbar: SnackbarService,
				private _fileManagementService: FileManagementService,
				private _copyService: CopyService,
				private _rackService: RackService,
				private _synopticService: SynopticService,
				private _siteService: SiteService,
				private _assetService: AssetService,
				private _parameterService: ParameterService,
				public override keycloakService: KeycloakService,
				private _lockService: LockService,
				private _userService: UserService) {
		super(keycloakService);
		this.form = this._fb.group({
			name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
			size: new UntypedFormControl(1, [Validators.required, Validators.min(1), Validators.max(200)]),
			depth: new UntypedFormControl(600, [Validators.min(0), Validators.max(5000)]),
			bothSide: new UntypedFormControl(false),
			structure: new UntypedFormControl(null),
			color: new UntypedFormControl(null, [Validators.maxLength(255)]),
			frontDoorCb: new UntypedFormControl(false),
			frontDoor: new UntypedFormControl(null),
			backDoorCb: new UntypedFormControl(false),
			backDoor: new UntypedFormControl(null),
			sideCb: new UntypedFormControl(false),
			side: new UntypedFormControl(false),
			feetCb: new UntypedFormControl(false),
			feet: new UntypedFormControl(null),
			frontLock: new UntypedFormControl(false),
			backLock: new UntypedFormControl(false)
		});

		this.rackSize = this.form.get('size').value;
		this.canUnlock = !this.isCustomer;
		this._lock();
	}

	ngOnInit(): void {
		this.sharedService.showLoader();
		if (this.isAdminOrUser) {
			this._parameterService.findAllByTypes([
					ParameterTypeEnum.RACK_DEPTH,
					ParameterTypeEnum.RACK_DOOR_TYPE,
					ParameterTypeEnum.RACK_FEET_TYPE,
					ParameterTypeEnum.RACK_SIDE_TYPE,
					ParameterTypeEnum.RACK_STRUCTURE_TYPE
				],
				['value', 'type', 'order']).subscribe(res => {
				if (!res) {
					return;
				}
				for (const parameter of res) {
					if (!this.parametersMap.has(parameter.type)) {
						this.parametersMap.set(parameter.type, []);
					}
					this.parametersMap.get(parameter.type).push(parameter.value);
				}
			});
		}

		this.subscriptions.push(
			this._activeRoute.paramMap.subscribe(async paramMap => {
				if (paramMap.has('uuid')) {
					this.rackLoaded.next(false);
					if (paramMap.has('version')) {
						await this._loadData(paramMap.get('uuid'), +paramMap.get('version'));
					} else {
						await this._loadData(paramMap.get('uuid'));
					}
					const rackFilter: RackFilter = new RackFilter();
					const synoFilter: SynopticFilter = new SynopticFilter();
					let siteFilter: SiteFilter;
					rackFilter.filterSubQueryToo = true;
					synoFilter.filterSubQueryToo = true;

					if (this.rack?.project) {
						rackFilter.projectUuid = this.rack.project.uuid;
						synoFilter.projectUuid = this.rack.project.uuid;
						// Récupérer tous les sites possibles des synos du projet pour les racks (pour créer et récupérer les racks aussi)
						siteFilter = {ofSynoOfProject: this.rack.project?.uuid};
					} else {
						rackFilter.notInProject = true;
						synoFilter.notInProjectOrPreSale = true;
						synoFilter.customer = this.rack.site?.customer?.uuid;
						// Récupérer tous les sites possibles du client pour les racks (pour créer et récupérer les racks aussi)
						siteFilter = {customerUuid: this.rack.site?.customer?.uuid};
					}

					concat(
						this._siteService.filterAll(siteFilter, ['uuid', 'name']).pipe(map(sites => {
							this.sites = sites || [];
							rackFilter.sites = this.sites.map(value => value.uuid);
						})),
						// Récupérer tous les racks possibles pour les sites (pour switcher d’un rack à l’autre)
						this._rackService.filterAll(rackFilter, ['uuid', 'version', 'name']).pipe(map(racks => this.racks = racks || [])),
						this._synopticService.filterAll(synoFilter, ['uuid', 'version'])
							.pipe(map(synos => this.synosInSameState = synos || []))
					).subscribe();

					this.sharedService.hideLoader();
					this.rackLoaded.next(true);
				}
			})
		);

		this.subscriptions.push(
			combineLatest([this.rackLoaded, this.pageLoaded])
				.subscribe(([pageLoaded, rackLoaded]) => {
					this.loaded = !!(pageLoaded && rackLoaded);
				})
		);
	}

	ngAfterViewInit(): void {
		this.openDrawer(0);

		this.pageStyle = this.document.createElement('style');
		this.pageStyle.innerHTML = '@page {size: A4 portrait; margin: 1cm;}';
		const head: HTMLHeadElement = this.document.querySelector('head');
		head.appendChild(this.pageStyle);
		this.pageLoaded.next(true);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(value => value.unsubscribe());
		this.pageStyle.remove();
		// Si on est pas en readonly, on unlock
		if (!this.readonly) {
			this.lock();
		}
	}

	@HostListener('window:beforeunload', ['$event'])
	async handleClose(e: BeforeUnloadEvent): Promise<void> {
		if (!this.readonly) {
			e.preventDefault();
			await this.lock();
		}
	}

	hasUnsavedChanges(): boolean {
		if (this.isCustomer) {
			return false;
		}

		return !isEqual(this.form.value, this.formPristine)
			|| !isEqual(this.assets, this.pristineAssets)
			|| !isEqual(this.rackJsonPristine, JSON.stringify(this.rackgraph.serialize()));
	}

	onSizeChange(): void {
		clearTimeout(this.rackSizeTimeout);
		this.rackSizeTimeout = setTimeout(() => {
			this.form.get('size').setValue(this.rackSize);
			this.rackSizeArray = Array(this.form.get('size').value).fill(null);
			this._cdRef.detectChanges();
		}, 700);
	}

	clearInput(event: MatCheckboxChange, field: string, secondField?: string): void {
		if (!event.checked) {
			this.form.get(field).patchValue(null);

			if (secondField) {
				this.form.get(secondField).patchValue(false);
			}
		}
	}

	openDrawer(index: number): void {
		this.menuContent.forEach(value => value.active = false);
		this.menuContent[index].active = true;

		if (this.sideView !== index || !this.drawer.opened) {
			this.drawer.open();
			this.sideView = index;
		} else {
			this.drawer.close();
		}
	}

	closeDrawer(): void {
		this.menuContent.forEach(value => value.active = false);
		this.drawer.close();
	}

	trackByRoomUuidFn(index: number, object: RackAssetsView): number | string {
		if (!object || !object.roomUuid) {
			return index;
		}
		return object.roomUuid;
	}

	trackByUuidFn(index: number, object: AbstractIdentifier): number | string {
		return Utils.trackByIdentifier(index, object);
	}

	showHideRacked(event: MatCheckboxChange): void {
		this.hideRacked = event.checked;
		this._generateAssetsView();
	}

	addItemOfType(rackView: RackAssetsView): void {
		const itemName: string = this._translator.translate('rack.accessory.' + rackView.roomUuid) + ' ' + (rackView.articles.length + 1);
		this._dialog.open(RackAddAccessoryDialogComponent, {data: {name: itemName}})
			.afterClosed()
			.subscribe(res => {
				if (!res) {
					return;
				}
				const asset: Asset = new Asset(rackView.roomUuid as AssetType);
				asset.name = res.name;
				asset.rackAccessoryHeight = res.size;

				rackView.open = true;

				this.assets.push(asset);
				this._generateAssetsView();
			});
	}

	editItemOfType(rackView: RackAssetsView, item: RackAssetsViewArticle): void {
		this._dialog.open(RackAddAccessoryDialogComponent, {
			data: {
				name: item.name,
				size: item.height
			}
		})
			.afterClosed().subscribe(res => {
			if (res) {
				let indexOfAsset: number;
				if (item.uuid) {
					indexOfAsset = this.assets.findIndex(value => value.uuid === item.uuid);
				} else {
					indexOfAsset = this.assets.findIndex(value =>
						value.name === item.name
						&& !value.uuid
						&& value.type === rackView.roomUuid
						&& value.rackAccessoryHeight === item.height);
				}

				this.assets[indexOfAsset].name = res.name;
				this.assets[indexOfAsset].rackAccessoryHeight = res.size;
			}

			this._generateAssetsView();
		});
	}

	removeItemOfType(rackView: RackAssetsView, item: RackAssetsViewArticle): void {
		this._dialog.open(ConfirmDialogComponent).afterClosed().subscribe(res => {
			if (res) {
				let indexOfAsset: number;
				if (item.uuid) {
					indexOfAsset = this.assets.findIndex(value => value.uuid === item.uuid);
					this._assetService.deleteByUuid(item.uuid, ['uuid']).pipe(first()).subscribe();
				} else {
					indexOfAsset = this.assets.findIndex(value =>
						value.name === item.name
						&& !value.uuid
						&& value.type === rackView.roomUuid
						&& value.rackAccessoryHeight === item.height);
				}

				if (indexOfAsset !== -1) {
					this.assets.splice(indexOfAsset, 1);
				}
			}

			this._generateAssetsView();
		});
	}

	delete(): void {
		if (!this.isAdmin) {
			return;
		}
		this._dialog.open(ConfirmDialogComponent).afterClosed().subscribe(res => {
			if (res) {
				this._rackService.deleteByUuidAndVersion(this.rack.uuid, this.rack.version, ['uuid']).pipe(first()).subscribe();
				this.forceExit = true;
				this.backBtn.goBack();
			}
		});
	}

	exitAndSave(): Promise<boolean> {
		return this.save();
	}

	async save(button?: HTMLButtonElement): Promise<boolean> {
		if (!this.hasUnsavedChanges()) {
			this._snackbar.showWarning('app.noChange');
			return false;
		}

		if (this.form.invalid) {
			return false;
		}

		if (!await this._checkLockBeforeSaveIsMine()) {
			this._dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
				data: {
					showYesBtn: false,
					type: 'error',
					title: 'rack.lockedSave.title',
					content: 'rack.lockedSave.content',
					btnNo: 'app.close'
				}
			});
			this._lock();
			return false;
		}
		if (button) {
			button.disabled = true;
		}
		this.sharedService.showLoader();
		this.rack = Object.assign(this.rack, this.form.value);
		this.rack.json = JSON.stringify(this.rackgraph.serialize());
		const toSave: RackInput = plainToClass(RackInput, this.rack, {excludeExtraneousValues: true});
		toSave.siteUuid = this.rack.site.uuid;
		// Update
		await firstValueFrom(this._rackService.update(this.rack.uuid, toSave, ['uuid'])).catch(err => {
			if (button) {
				button.disabled = false;
			}
			throw err;
		});

		// Sauvegarder les assets
		if (this.assets) {
			const multipleAssetInputs: MultipleAssetInput = new MultipleAssetInput();
			for (const asset of this.assets) {
				// pour chaque asset, on va comparer ceux qui ont été ajouté, ceux qui ont changé et ceux qui ont été supprimé
				if (!asset.uuid) {
					// l’actif à été ajouté
					multipleAssetInputs.addedAssets.push(this._createAssetInput(asset));
					this._addShelfedAssetsToMultipleAssetsInput(asset, multipleAssetInputs);
				} else {
					// l’actif à été potentiellement mis à jour
					const pristineAsset: Asset = this.pristineAssets.find(value => value.uuid === asset.uuid);
					if (!pristineAsset) {
						console.warn('bizarre l’article à un uuid mais n’est pas dans les actifs de la bdd...');
					}
					if (!isEqual(pristineAsset, asset)) {
						multipleAssetInputs.updatedAssets.push(this._createAssetInput(asset));
						this._addShelfedAssetsToMultipleAssetsInput(asset, multipleAssetInputs);
					}
				}
			}
			// Check des articles supprimé
			for (const pristineAsset of this.pristineAssets) {
				// pour chaque actif, on va check si on l’à pas supprimé
				if (!this.assets.some(value => value.uuid === pristineAsset.uuid)) {
					multipleAssetInputs.deletedAssets.push(pristineAsset.uuid);
				}
			}

			await firstValueFrom(this._assetService.saveMultiple(multipleAssetInputs, this.rack.uuid, this.rack.version, 'RACK', ASSET_FIELDS))
				.catch(err => {
					if (button) {
						button.disabled = false;
					}
					throw err;
				});
		}

		this.formPristine = cloneDeep(this.rack);
		this.pristineAssets = cloneDeep(this.assets);
		if (button) {
			button.disabled = false;
		}

		await this._loadData(this.rack.uuid, this.rack.version);
		this.sharedService.hideLoader();
		this._snackbar.showSuccess('app.saved');
		return true;
	}

	onElementPlaced(element: RackRow): void {
		if (!element.data) {
			return;
		}
		const data: Asset = element.data;
		const rack: Rack = clone(this.rack);
		rack.json = null;
		data.rack = rack;
		data.rackgraphId = element.rackgraphId;
		this._generateAssetsView();
	}

	removeElementFromRack = (row: RackRow, index: number, backRack: boolean, callback: () => void): void => {
		this._dialog.open(ConfirmDialogComponent).afterClosed().subscribe(res => {
			if (res) {
				callback();
			}
		});
	};

	onElementRemovedFromRack(element: { oldRow: RackRow, newRow: RackRow, index: number, backRack: boolean }): void {
		const data: Asset = element.oldRow.data;
		delete data.rack;
		delete data.rackgraphId;
		element.newRow.data = {comment: null};
		this._generateAssetsView();
	}

	print(): void {
		const actualTitle: string = this._titleService.getTitle();
		this._titleService.setTitle(null, this.rack.name);
		setTimeout(() => {
			window.print();
			this._titleService.setTitle(null, actualTitle);
		}, 200);
	}

	addRackSchema(): void {
		this._dialog.open(RackAddDialogComponent,
			{
				data: {
					sites: this.sites,
					project: this.rack.project?.uuid
				}
			})
			.afterClosed().subscribe(async res => {
			if (!res) {
				return;
			}
			if (res.create) {
				let rack: Rack;
				const rackInput: RackInput = plainToInstance(
					RackInput,
					{
						...res.rack,
						state: GenericTriStateEnum.ACTIVE
					},
					{excludeExtraneousValues: true});
				if (this.rack.project) {
					rackInput.projectUuid = this.rack.project.uuid;
				}

				rack = await firstValueFrom(this._rackService.create(rackInput, ['uuid', 'version', 'name', 'site {uuid, name}']));
				this.racks.push(rack);
				this.goToRack(rack);
			} else {
				// Copier le rack pour en faire une nouvelle version !
				firstValueFrom(this._copyService.createNewVersionOfRack(res.rack.uuid, res.rack.version, this.rack.project.uuid, ['uuid', 'version', 'name']))
					.then(newVersion => {
						this.racks.push(newVersion);
						this.goToRack(newVersion);
					});
			}
		});
	}

	goToRack(rack: Rack): void {
		this._router.navigate(['../..', rack.uuid, rack.version], {
			relativeTo: this._activeRoute,
			queryParamsHandling: 'preserve'
		});
	}

	goToSynoptic(): void {
		this._router.navigate(['synoptic', this.synosInSameState[0].uuid, this.synosInSameState[0].version], {
			queryParams: {
				back: this._activeRoute.snapshot.queryParamMap.get('back')
			}
		});
	}

	updateComment(value: any, idx: number, front?: boolean): void {
		this.rackgraph.updateRow(idx, value, front);
	}

	async lock(): Promise<void> {
		// Unlock dans la bdd et lock la vue
		await firstValueFrom(this._lockService.unlock('rack', this.rack.uuid, this.rack.version));
		this._lock();
	}

	checkAndUnlock(): void {
		this.sharedService.showLoader();
		this._lockService.checkLock('rack', this.rack.uuid, this.rack.version).subscribe(async res => {
			this.sharedService.hideLoader();
			if (!res) {
				// Si pas de lock, on unlock et on créé un lock pour cet utilisateur
				this._unlock();
				firstValueFrom(this._lockService.lock('rack', this.rack.uuid, this.rack.version));
			} else if (res.creator === this.userSub) {
				// Si l’utilisateur est le même que celui du lock, on unlock
				this._unlock();
			} else {
				// Sinon on affiche une modal pour dire que c’est lock
				const user: User = await firstValueFrom(this._userService.getUser(res.creator));

				this._dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
					data: {
						showYesBtn: false,
						type: 'warning',
						btnNo: 'app.close',
						useTranslateForContent: false,
						title: 'rack.locked.title',
						content: this._translator.translate('rack.locked.content', {
							user: user.firstName + ' ' + user.lastName,
							date: formatDate(res.createdAt, this._translator.translate('app.dateFormat'), this._translator.getActiveLang()),
							hour: formatDate(res.createdAt, this._translator.translate('app.hourFormat'), this._translator.getActiveLang())
						})
					}
				});
			}
		});
	}

	private _addShelfedAssetsToMultipleAssetsInput(asset: Asset, multipleAssetInputs: MultipleAssetInput): void {
		if (asset?.shelfAssets?.length) {
			asset.shelfAssets.forEach(value => {
				value.shelf = asset;
				value.rack = asset.rack;
				if (value.uuid) {
					multipleAssetInputs.updatedAssets.push(this._createAssetInput(asset));
				} else {
					multipleAssetInputs.addedAssets.push(this._createAssetInput(asset));
				}
			});
		}
	}

	private _checkLockBeforeSaveIsMine(): Promise<boolean> {
		// Check if there is still a lock on view and that this lock is mine in order to be able to save
		return firstValueFrom(this._lockService.checkLock('rack', this.rack.uuid, this.rack.version))
			.then(res => res && res.creator === this.userSub);
	}

	private _lock(): void {
		this.readonly = true;
		this.form.disable();
	}

	private _unlock(): void {
		this.readonly = false;
		this.form.enable();
	}

	private _createAssetInput(asset: Asset): AssetInput {
		const input: AssetInput = plainToClass(AssetInput, asset, {excludeExtraneousValues: true});
		input.siteUuid = this.rack.site.uuid;
		if (asset.article) {
			input.articleUuid = asset.article.uuid;
			input.articleVersion = asset.article.version;
		}
		if (asset.shelf) {
			input.shelfUuid = asset.shelf.uuid;
		}
		if (asset.rack) {
			input.rackUuid = asset.rack.uuid;
			input.rackVersion = asset.rack.version;
		}
		if (this.rack.project) {
			input.projectUuid = this.rack.project.uuid;
		}
		return input;
	}

	private async _loadData(uuid: string, version?: number): Promise<void> {
		let request: Observable<Rack> = this._rackService.get(uuid, RACK_FIELDS);
		if (version) {
			request = this._rackService.getWithVersion(uuid, +version, RACK_FIELDS);
		}
		await firstValueFrom(request)
			.then(value => {
				if (!value) {
					return;
				}
				this.rack = value;
				this.canUnlock = !this.isCustomer && this.rack.lastVersion;
				if (this.isCustomer) {
					this.parametersMap.set(ParameterTypeEnum.RACK_DEPTH, [this.rack.depth?.toString()]);
					this.parametersMap.set(ParameterTypeEnum.RACK_DOOR_TYPE, [this.rack.frontDoor]);
					this.parametersMap.set(ParameterTypeEnum.RACK_FEET_TYPE, [this.rack.feet]);
					this.parametersMap.set(ParameterTypeEnum.RACK_SIDE_TYPE, [this.rack.side]);
					this.parametersMap.set(ParameterTypeEnum.RACK_STRUCTURE_TYPE, [this.rack.structure]);
				}
				this.rackJsonPristine = this.rack.json as string;

				if (this.rack.json) {
					this.rack.json = JSON.parse(this.rack.json as string);
				}

				this.rackSizeArray = Array(value.size).fill(null);
				this.rackSize = value.size;

				this.form.patchValue(value);
				this.form.patchValue({
					frontDoorCb: !!value.frontDoor,
					backDoorCb: !!value.backDoor,
					sideCb: !!value.side,
					feetCb: !!value.feet
				});

				if (this.readonly) {
					this.form.disable();
				}

				this.formPristine = cloneDeep(this.form.value);
			})
			.catch(err => {
				this.forceExit = true;
				this._router.navigate(['projects']);
				throw err;
			});

		const assetFilter: AssetFilter = new AssetFilter();
		if (this.canUnlock) {
			assetFilter.forRackSite = this.rack.site.uuid;
			if (this.rack.project) {
				assetFilter.projectUuid = this.rack.project.uuid;
			} else {
				assetFilter.stateOfArt = true;
			}
		}
		assetFilter.rackVersion = this.rack.version;
		assetFilter.rackUuid = this.rack.uuid;
		await firstValueFrom(this._assetService.filterAll(assetFilter, ASSET_FIELDS)).then(assets => {
			this.assets = assets;
			this.pristineAssets = cloneDeep(this.assets);
			this._generateAssetsView();
		});
		if (!this.rack.json) {
			this.rackgraph.configure(null);
			this.rack.json = this.rackgraph.serialize();
		}
		if ((this.rack.json as RackJson).front) {
			(this.rack.json as RackJson).front.forEach(this._fillDataWithCorrespondingAssetInfo());
		}
		if ((this.rack.json as RackJson).back) {
			(this.rack.json as RackJson).back.forEach(this._fillDataWithCorrespondingAssetInfo());
		}
		this.rackgraph.configure(this.rack.json as RackJson);
	}

	private _fillDataWithCorrespondingAssetInfo(): (value: RackRow) => void {
		return value => {
			const assetsCorresponding: Asset[] = this.assets.filter(asset => asset.rackgraphId
				&& asset.rackgraphId === value.rackgraphId
				&& asset.rack?.uuid === this.rack.uuid);
			if (assetsCorresponding?.length) {
				value.data = assetsCorresponding[0];
			} else if (!value.data) {
				value.data = {comment: null};
			}
		};
	}

	private _generateAssetsView(): void {
		const openRoomUuid: string[] = [];
		if (this.assetsView) {
			openRoomUuid.push(...this.assetsView.filter(value => value.open).map(value => value.roomUuid));
		}

		this.assetsView = [];
		if (!this.assets) {
			this.assets = [];
			return;
		}

		for (const asset of this.assets) {
			if (asset.rackgraphId && this.hideRacked) {
				continue;
			}

			let room: RackAssetsView;
			let roomUuid: string;
			let roomName: string;
			switch (asset.type) {
				case AssetType.ARTICLE:
				case AssetType.GENERIC:
				case AssetType.LINK:
					roomUuid = asset.synoptic?.room?.uuid;
					roomName = asset.synoptic?.room?.name;
					break;
				case AssetType.RACK_SHELF:
					// On se sert de room uuid pour mettre le type des éléments accessoires
					roomUuid = AssetType.RACK_SHELF;
					roomName = this._translator.translate('rack.shelf');
					break;
				case AssetType.RACK_FILLER:
					// On se sert de room uuid pour mettre le type des éléments accessoires
					roomUuid = AssetType.RACK_FILLER;
					roomName = this._translator.translate('rack.filler');
					break;
				case AssetType.RACK_SOCKET:
					// On se sert de room uuid pour mettre le type des éléments accessoires
					roomUuid = AssetType.RACK_SOCKET;
					roomName = this._translator.translate('rack.socket');
					break;
			}

			// Chercher l’asset view de la salle
			room = this.assetsView.find(value => value.roomUuid === roomUuid);
			if (!room) {
				// S’il n’existe pas, on créé un nouvel asset view
				room = new class implements RackAssetsView {
					articles: RackAssetsViewArticle[] = [];
					open: boolean = openRoomUuid.indexOf(roomUuid) !== -1;
					roomName: string = roomName;
					roomUuid: string = roomUuid;
				};
				this.assetsView.push(room);
			}

			if (asset.shelf) {
				continue;
			}

			const article: RackAssetsViewArticle = new class implements RackAssetsViewArticle {
				height: number = asset.article ? asset.article.rackable : asset.rackAccessoryHeight;
				name: string = asset.name;
				rackgraphId: number = asset.rackgraphId;
				uuid: string = asset.uuid;
				imageBlob: string;
				asset: Asset = asset;
			};

			if (asset.article?.imageUrl) {
				this._fileManagementService.getFileBlob('article', asset.article.imageUrl, 200, 200).then(res => {
					article.imageBlob = res;
				});
			}
			if (asset.shelfAssets?.length) {
				article.shelfAssetsImages = new Map<string, string>();
				for (const shelfAsset of asset.shelfAssets) {
					if (shelfAsset.article?.imageUrl) {
						this._fileManagementService.getFileBlob('article', shelfAsset.article.imageUrl, 200, 200)
							.then(res => {
								article.shelfAssetsImages.set(shelfAsset.uuid, res);
							});
					}
				}
			}
			room.articles.push(article);
		}

		const assetTypes: string[] = [AssetType.RACK_SHELF, AssetType.RACK_FILLER, AssetType.RACK_SOCKET];
		for (const type of assetTypes) {
			// Vérifier qu’il y a chaques accessoires
			let accessoryType: RackAssetsView = this.assetsView.find(value => value.roomUuid === type);
			if (!accessoryType) {
				// S’il n’existe pas, on créé un nouvel asset view
				accessoryType = new class implements RackAssetsView {
					articles: RackAssetsViewArticle[] = [];
					open: boolean;
					roomName: string;
					roomUuid: string = type;
				};
				accessoryType.roomName = this._translator.translate('rack.' + type.split('_')[1].toLowerCase());
				this.assetsView.push(accessoryType);
			}
		}

		this.assetsView.sort((a, b) => {
			if (assetTypes.includes(a.roomUuid) && !assetTypes.includes(b.roomUuid)) {
				return 1;
			} else if (!assetTypes.includes(a.roomUuid) && assetTypes.includes(b.roomUuid)) {
				return -1;
			} else if (!assetTypes.includes(a.roomUuid) && !assetTypes.includes(b.roomUuid)) {
				return a.roomUuid > b.roomUuid ? -1 : 1;
			} else if (assetTypes.includes(a.roomUuid) && assetTypes.includes(b.roomUuid)) {
				if (a.roomUuid === AssetType.RACK_SHELF) {
					return -1;
				} else if (b.roomUuid === AssetType.RACK_SHELF) {
					return 1;
				} else if (a.roomUuid === AssetType.RACK_SOCKET) {
					return 1;
				} else if (b.roomUuid === AssetType.RACK_SOCKET) {
					return -1;
				}
			}
		});
	}
}
